import React, { useEffect, useMemo } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ExpandMoreIcon from "@material-ui/icons/ArrowDropDown";
import Grow from "@material-ui/core/Grow";
import InputBase from "@material-ui/core/InputBase";
import Popper from "@material-ui/core/Popper";

import AutoSizer from "react-virtualized-auto-sizer";
import { Field } from "react-final-form";
import { FixedSizeList as List } from "react-window";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    inputWrapper: {
      position: "relative",
      display: "flex",
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      "&:hover": {
        borderBottomColor: "2px solid black",
      },
    },
    input: {
      width: "100%",
    },
    inputIcon: {
      marginRight: "1px",
    },
    popper: {
      maxHeight: "calc(100vh - 300px)",
      height: "calc(100vh - 300px)",
      width: "600px",
      overflow: "hidden",
      overflowY: "auto",
      background: "white",
      zIndex: 1000000,
      paddingTop: 10,
      paddingBottom: 10,
      border: "1px solid #ececec",
      borderRadius: "4px",
    },
    selectRow: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#ececec",
      },
    },
    searchInput: {
      opacity: 0,
      position: "absolute",
      width: "100%",
      zIndex: 1000000,
    },
  });

type SelectType = {
  display: string;
  value: string;
  search: string;
};

type CustomComboSelectionProps = {
  field: string;
  data: SelectType[];
  required?: boolean;
  selectedValue: any;
  handleSelect(selected: any): void;
} & WithStyles<typeof styles>;

const CustomComboSelectionUnstyled: React.FC<CustomComboSelectionProps> = ({ classes, field, data, selectedValue, handleSelect, required }) => {
  const [searchIndx, setSearchIndx] = React.useState<number>(0);
  const [search, setSearch] = React.useState<string>("");
  const [open, setOpen] = React.useState(false);

  const anchorEl = React.useRef(null);
  const listRef = React.useRef(null);

  let typingTimer; // timer identifier
  let doneTypingInterval = 1000; // time in ms

  useEffect(() => {
    if (data) {
      const indx = data.findIndex((row) => row.value === selectedValue);
      setSearchIndx(indx);
    }
  }, [data]);

  const handleOpenDropdown = () => {
    setOpen(true);
  };

  const handleItemSelect = (selected: string) => {
    handleSelect(selected);
    handleMenuClosed();
  };

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    if (search.length > 0 && open) {
      const indx = data.findIndex((row) => row.search.toLowerCase().includes(search.toLowerCase()));
      if (indx >= 0) {
        scrollToItem(indx, "center");
      }
    }
  }, [search, data]);

  const scrollToItem = (index: number, position?: string) => {
    if (index >= 0 && listRef.current && open) {
      listRef.current.scrollToItem(index, position); // go to item in list
      setSearchIndx(index);
    }
  };

  const handleMenuClosed = () => {
    setOpen(false);
    setSearch("");
    setSearchIndx(-1);
    clearTimeout(typingTimer);
  };

  const doneTyping = () => {
    setSearch("");
  };

  const handleKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(doneTyping, doneTypingInterval);
  };

  const handleKeyDown = (e) => {
    clearTimeout(typingTimer);
    if (e.keyCode == 13) {
      const selectedItem = data[searchIndx].value;
      handleItemSelect(selectedItem);
      e.preventDefault();
    } else if (e.keyCode === 38 && searchIndx != 0) {
      scrollToItem(searchIndx - 1);
    } else if (e.keyCode === 40 && searchIndx != data.length) {
      scrollToItem(searchIndx + 1);
    }
  };

  const value = useMemo(() => {
    if (data) {
      const item = data.find((item) => item.value === selectedValue);
      if (item) {
        return item.display;
      }
      return "";
    }
    return "";
  }, [data, selectedValue]);

  return (
    <React.Fragment>
      <div ref={anchorEl}>
        <Field
          required={required}
          name={field}
          component={({ input }) => (
            <div className={classes.inputWrapper} onClick={handleOpenDropdown}>
              <InputBase {...input} value={value} className={classes.input} />
              <ExpandMoreIcon className={classes.inputIcon} />
              {open && <InputBase value={search} autoFocus={true} onKeyUp={handleKeyUp} onKeyDown={handleKeyDown} onChange={handleInputChange} className={classes.searchInput} />}
            </div>
          )}
        />
      </div>
      <Popper transition open={open} disablePortal role={undefined} anchorEl={anchorEl.current} className={classes.popper}>
        {({ TransitionProps, placement }) => (
          <ClickAwayListener onClickAway={handleMenuClosed}>
            <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    ref={listRef}
                    height={height}
                    width={width}
                    itemCount={data.length}
                    itemSize={35}
                    itemData={{
                      data: data,
                      classes: classes,
                      searchIndx: searchIndx,
                      handleSelect: handleItemSelect,
                      selected: selectedValue,
                    }}
                  >
                    {Row}
                  </List>
                )}
              </AutoSizer>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </React.Fragment>
  );
};

const Row = ({ index, style, data: { data, searchIndx, classes, handleSelect, selected } }) => {
  const row = data[index];

  return (
    <div
      className={classes.selectRow}
      onClick={() => handleSelect(row.value)}
      style={{
        ...style,
        backgroundColor: selected === row.value ? "#cecece" : searchIndx === index && "#ececec",
      }}
    >
      <span style={{ marginLeft: 10, marginRight: 10 }}>{row.display}</span>
    </div>
  );
};

export default withStyles(styles)(CustomComboSelectionUnstyled);
